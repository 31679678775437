import React from "react";
import Layout from "../components/layout";
import styled, { css } from "styled-components";
import { Container, Col, Row } from "../components/container";
import Heading from "../components/heading";
import { Button } from "../components/button";
import NHSLogo from "../images/nhs.png";
import DiabetesUKLogo from "../images/diabetesUK.png";

const StyledHeading = styled(Heading)`
  padding-top: ${props => props.theme.spacing.verySmall};
  margin-bottom: 0;
  margin-top: 0;
`;

const StyledParagraph = styled.p`
  padding-top: ${props => props.theme.spacing.regular};
  margin-top: 0;
  margin-bottom: 0;

  ${props =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;

const StyledVideo = styled.iframe`
  padding-top: ${props => props.theme.spacing.regular};
  width: 100%;
  height: 200px;
  outline: 0;
  border: 0;
`;

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing.regular};
  margin-bottom: ${props => props.theme.spacing.huge};
`;

const StyledLogoRow = styled(Row)`
  margin-top: ${props => props.theme.spacing.regular};
  align-items: flex-end;
  justify-content: space-between;

  img {
    width: 35%;
    max-width: 100%;
  }
`;

const StyledBottomBar = styled.div`
  position: fixed;
  bottom: 0;
  width: calc(100% - ${props => props.theme.spacing.regular} * 2);
  background-color: ${props => props.theme.color.backgroundWhite};
`;

const StyledContainer = styled(Container)`
  padding-bottom: 165px;
`;

const IndexPage = () => (
  <Layout rightButtonLink="/" rightButtonText="close">
    <StyledContainer>
      <Row>
        <Col>
          <StyledHeading elem="h1" size={Heading.sizes.veryLarge}>
            We can help you to manage your diabetes
          </StyledHeading>
          <StyledParagraph>
            Whether you've been recently diagnosed, or have been living with
            diabetes for a while now, it can be challenging to manage your
            condition.
          </StyledParagraph>
          <StyledVideo
            src="https://player.vimeo.com/video/295175028?title=0&byline=0"
            width="640"
            height="360"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
          <StyledParagraph bold>
            Helping you to you live well with diabetes
          </StyledParagraph>
          <StyledParagraph>
            Our diabetes management tool includes NHS-accredited courses and
            advice about a range of topics. We also give you tools to track your
            symptoms and share up-to-date monitoring information with your
            healthcare team.
          </StyledParagraph>
        </Col>
      </Row>
      <StyledBottomBar>
        <StyledLogoRow wrap={false}>
          <img src={DiabetesUKLogo} alt="Diabetes UK" />
          <img src={NHSLogo} alt="NHS logo" />
        </StyledLogoRow>
        <Row>
          <Col>
            <StyledButton to="/what">Find out more</StyledButton>
          </Col>
        </Row>
      </StyledBottomBar>
    </StyledContainer>
  </Layout>
);

export default IndexPage;
